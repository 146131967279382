import * as React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

export default () => (
  <Layout>
    <Helmet>
      <title>404 Not Found - Upright Performance</title>
    </Helmet>
    <Header />
    <Flex flexDirection="column" alignItems="center" mt={4} mb={7}>
      <h2>You are here!</h2>
      <h3 style={{ color: '#5b63fe' }}>
        But nothing else is{' '}
        <Link to="/">
          <u>#404</u>
        </Link>
      </h3>
    </Flex>
  </Layout>
)
